.App {
  text-align: center;
}
.App > div {
  background-color: #313338;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App > div > div {
  aspect-ratio: 1.143;
  width:min(calc(99vh / 1.1),50vw)
} .App > div > div.TaxCalculator {
  height:100%;
}

.App > div > div > div > input[type="button"] {
  margin-right:5px;
  margin-left:5px;
}

h1 {
  margin:0px;
}

.TaxCalculator {
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  width:50%;
  font-size:xx-large;
  margin:10px;
  margin-left:15px;
}
.TaxCalculator > * {
  margin:2px;
}
.TaxCalculator > .input {
  display:flex;
  flex-direction: row;
  align-items: center;
}
.TaxCalculator > .input > input {
  font-size:large;
  padding:0px;
  padding-top:2px;
  margin-top:8px;
}
.TaxCalculator > .final {
  font-size:2em;
}

@media print {
  .App > div > div {
    width:0%;
    opacity:0%;
  }
  .App > div > div.TaxCalculator {
    width:100%;
    opacity:100%;
  }
  .TaxCalculator > .input {
    max-height:0%;
    width:0%;
    opacity:0%;
  }
}